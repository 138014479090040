import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H3 } from "../../styles/TextStyles"

interface MaritimeCoursesProps {}

const MaritimeCourses: FunctionComponent<MaritimeCoursesProps> = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>Courses include, but are not limited to:</Title>
        <Description>Personal Survival Techniques Seafarers</Description>
        <Description>Fire Prevention and Fire Fighting</Description>
        <Description>Advanced Fire Fighting</Description>
        <Description> Elementary First Aid</Description>
        <Description>Survival Crafts and Rescue Boats</Description>
        <Description>Fast Rescue Boats</Description>
        <Description>Medical First Aid</Description>
        <Description>Medical Care</Description>
        <Description>Command & Control for EERV and Mates</Description>
        <Description>
          Basic Offshore Safety Induction and Emergency Training
        </Description>
        <Description>Further Offshore Emergency Training</Description>
        <Description>Offshore Emergency Response Team Member</Description>
        <Description>Offshore Emergency Response Team Leader</Description>
        <Description>Offshore Lifeboat Coxswain</Description>
        <Description>Further Coxswain Training</Description>
        <Description>
          Helicopter Landing Officer Initial Training (HLO) & C.A.A. VHF
          Aeronautical
        </Description>
        <Description>Helicopter Emergency Response Team Leader</Description>
        <Description>Helicopter Landing Officer Further Training</Description>
        <Description>Helideck Emergency Response Team Member</Description>
        <Description>Helicopter Refuelling</Description>
        <Description>
          Control Room Operator (CRO) ControllingEmergencies
        </Description>
        <Description>Authorized Gas Detector – Level 1</Description>
        <Description>Authorized Gas Detector - Level 3</Description>
        <Description>Efficient Cook</Description>
        <Description>Workshop Skills Training</Description>
        <Description>Ship security officer</Description>
        <Description>Able Seafarer Deck</Description>
        <Description>Basic Training for Oil tanker Familiarisation</Description>
        <Description>Crowd Management & passenger safety</Description>
        <Description>General Operations Certificate</Description>
        <Description>Security Awareness</Description>
        <Description>Electronic Navigation Systems & Management</Description>
        <Description>Fire Watcher</Description>
        <Description>Banksman - Slinging and Lifting Operations</Description>
        <Description>
          Lifting Operations and Equipment Regulations (LOLER),
        </Description>
        <Description>Appointed Person</Description>
        <Description>Crane Operation Stage 1</Description>
        <Description>Crane Operation Stage 2</Description>
        <Description>Crane Operations Stage 3 & 4</Description>
        <Description>
          Provision and Use of Work Equipment Regulations
        </Description>
        <Description>(PUWER)</Description>
        <Description>Working at Heights Level 1 User</Description>
        <Description>Working at Heights Level 2 Supervisors</Description>
        <Description>Working at Heights Level 3 Rescue</Description>
        <Description>Confined Space Entry</Description>
        <Description>Confined Space Supervisor</Description>
        <Description>Confined Space Rescue</Description>
        <Description>
          Training Oil Spill Response - Level 1 - First Responders
        </Description>
        <Description>
          Training Oil Spill Response - Level 2 - Supervisor / On Scene
          Commander
        </Description>
        <Description>
          Training Oil Spill Response - Level 3 Senior Managers and
          Administrations
        </Description>
        <Description>TRAINING - ELECTRICAL</Description>
        <Description>Non-electrical operations B0-H0(V)</Description>
        <Description>
          Accelerated Training- Officer in charge, Engineering watch
        </Description>
      </TextWrapper>
    </Wrapper>
  )
}

export default MaritimeCourses

const Wrapper = styled.div`
  padding: 0px 0 80px 0;
  @media (max-width: 640px) {
    padding: 80px 0 80px 0;
  }
`
const TextWrapper = styled.div`
  max-width: 820px;
  display: grid;
  margin: 0 auto;
  gap: 20px;
  @media (max-width: 640px) {
    max-width: 300px;
  }
`
const Title = styled(H3)`
  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 130%;
    font-weight: 500;
  }
`
const Description = styled.p`
  font-size: 14px;
  line-height: 180%;
  span {
    font-weight: bold;
  }
`
