import React, { FunctionComponent } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ContentWrapper,
  Description,
  FileWrapper,
  TextWrapperWrapper,
  Title,
  Wrapper,
} from "../../styles/HeroElement"

// import Enquirebutton from "../buttons/Enquirebutton"
import { graphql, useStaticQuery } from "gatsby"

interface HeroSectionProps {
  title?: string
  description?: string
}

const HeroSection: FunctionComponent<HeroSectionProps> = props => {
  const {
    title = "Maritime Training & Education",
    description = "We are a SAMSA recognised service provider of maritime courses that are STCW and OPITO approved.",
  } = props

  const data = useStaticQuery(graphql`
    query HeroQuery {
      file(relativePath: { eq: "maritime.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 10)
        }
      }
    }
  `)

  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapperWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {/* <Enquirebutton /> */}
        </TextWrapperWrapper>
        <FileWrapper>
          <GatsbyImage
            alt="image"
            image={data.file.childImageSharp.gatsbyImageData}
            style={styles}
          />
        </FileWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default HeroSection
const styles = {
  borderRadius: "20px",
}
