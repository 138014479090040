import React from "react"
// import ReviewCard from "../components/cards/ReviewsCard"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
// import ReviewsHeading from "../components/sections/Heading"
import HeroA from "../components/sections/HeroA"
import HeroSection from "../components/sections/HeroSection"

const Maritime = () => {
  return (
    <Layout>
      <SEO title="Maritime Training & Educatication" />
      <HeroSection />
      <HeroA />

      {/* <ReviewsHeading />
      <ReviewCard /> */}
    </Layout>
  )
}
export default Maritime
