export const filled = [
  {
    image: "/assets/backgrounds/circle1.jpeg",
  },
  {
    image: "/assets/backgrounds/circle2.jpeg",
  },
  {
    image: "/assets/backgrounds/circle3.jpeg",
  },
]
