import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { filled } from "../../data/cardData"

interface CircleProps {}

const Circle: FunctionComponent<CircleProps> = () => {
  return (
    <Container>
      {filled.map((item, index) => (
        <Image key={index} src={item.image} />
      ))}
    </Container>
  )
}

export default Circle

const Container = styled.div`
  padding: 100px 0 100px 0;
  display: grid;
  grid-template-columns: 200px 200px 200px;
  justify-content: center;
  gap: 100px;
  @media (max-width: 768px) {
    gap: 40px;
  }

  @media (max-width: 640px) {
    grid-template-columns: 200px;
    gap: 20px;
    display: none;
  }
`
const Image = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 90%;

  @media (max-width: 768px) {
    width: 130px;
    height: 130px;
  }
`
