import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1, Paragraph } from "../../styles/TextStyles"
import EnquireButton from "../buttons/Enquirebutton"
import Circle from "../cards/Circle"

import Line from "../lines/Line"
import MaritimeCourses from "./MaritimeCourses"

interface HeroAProps {
  title?: string
  subtitle?: string
}

const HeroA: FunctionComponent<HeroAProps> = props => {
  const {
    title = "Enroll  now",
    subtitle = "We offer courses in partnership with globally accredited institutions that will ensure you receive the necessary skills, quality training, and competence to advance your career in maritime, oil and offshore activities. All of our courses are SAMSA recognized and are STCW and OPITO approved. Further your individual career, and your employees' careers to ensure they are the most competent seafarers.",
  } = props
  return (
    <Wrapper>
      <LineWrapper>
        <Line />
      </LineWrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>{title} </Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextWrapper>
        <Circle />
        <MaritimeCourses />
        <ButtonWrapper>
          <EnquireButton />
        </ButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default HeroA

const Wrapper = styled.div`
  padding: 200px 38px;
`
const ContentWrapper = styled.div`
  margin: 100px 0 0 0;
  max-width: 1234px;
`
const TextWrapper = styled.div`
  max-width: 660px;
  display: grid;
  gap: 40px;
`

const Title = styled(H1)`
  font-weight: 700;

  // anninamtion

  @media (max-width: 640px) {
    font-size: 40px;
  }
`
const Subtitle = styled(Paragraph)`
  line-height: 130%;
`

const ButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  padding: 0 0 120px 0;
  @media (max-width: 640px) {
    padding: 40px 0 0px 0;
  }
`
const LineWrapper = styled.div`
  display: grid;
  justify-content: center;
`
